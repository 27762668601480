.send-email-manually {
  position: fixed;
  z-index: 103;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);

  display: none;
  flex-flow: column nowrap;

  width: 70vw;
  height: 65vh;
  padding: 50px 100px;

  background-color: $modal-bg-color;
  border: 1px solid $modal-border-color-main;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

  & h3 {
    @include noto-sans-font(16px, 19px);
    margin-bottom: 20px;
  }

  & label {
    @include noto-sans-font(14px, 17px);
    flex-shrink: 0;
    width: 200px;
  }

  & hr {
    width: 100%;
    margin-left: 0;
    border: none;
    box-shadow: 0 0 1px 1px rgb(0 0 0 / 25%);
  }

  & .btn-close {
    top: 10px;
    right: 10px;
  }

  &__row {
    display: flex;
    width: 90%;
    padding-top: 4px;
    padding-bottom: 4px;

    & input {
      @include noto-sans-font(15px, 18px);
      width: 80%;
      border: none;
    }

    &:first-of-type {
      padding: 0;
    }
  }

  &__textarea-wrapper {
    display: flex;
    flex-grow: 1;

    width: 100%;
    height: 100px;
    padding-top: 7px;

    & div {
      @include noto-sans-font(13px, 17px);
      resize: none;

      overflow-y: auto;

      width: 100%;
      height: 100%;

      white-space: pre-line;

      border: none;

      // Temp class for manually email sender
      & span {
        color: $midnight-blue;
      }

      & a {
        color: $cerulean;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__btn-wrapper {
    display: flex;
    align-self: flex-end;
    margin-top: 10px;

    & button:last-of-type {
      margin-left: 10px;
    }
  }
}
