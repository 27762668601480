.mri-rewrite-modal {
  position: fixed;
  z-index: 103;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: none;
  flex-wrap: wrap;
  justify-content: space-evenly;

  box-sizing: border-box;
  max-width: 600px;
  padding: 45px;

  background-color: $modal-bg-color;
  border: 1px solid $modal-border-color-main;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

  &__text {
    width: 100%;
    margin-top: 0;
    margin-bottom: 30px;

    & b {
      text-decoration: underline;
    }
  }

  &__values {
    width: 100%;
    margin: 0;
    padding: 0;

    &:last-of-type {
      margin-bottom: 30px;
    }
  }

  &__close-btn {
    top: 15px;
    right: 15px;
  }
}
