.registration-check {
  display: flex;
  flex-direction: column;
  align-items: center;

  & h3 {
    margin: 0;
  }

  & span {

    @include noto-sans-font(16px, 16px);
    margin-top: 5px;
  }

  & h4 {
    margin: 0;
    margin-bottom: 20px;
    line-height: 24px;
  }

  & hr {
    width: 100%;
    height: 1px;
    margin: 0;
    padding: 0;

    background-color: $silver;
    border: none;
  }

  & p {
    margin: 20px 0;
    line-height: 24px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__section {
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    margin-top: 30px;
    padding: 20px;

    background-color: $alabaster;
    border: 1px solid $cerulean;
  }
}

@media (min-width: $desktop-width) {
  .registration-check {
    &__wrapper {
      flex-direction: row;
      justify-content: space-between;
    }

    &__section {
      width: 48%;

      & a {
        margin-top: auto;
      }
    }
  }
}
