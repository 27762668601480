.mri-upload-modal {
  position: fixed;
  z-index: 102;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: none;
  flex-direction: column;

  box-sizing: border-box;
  width: 70vw;
  padding: 60px;

  background-color: $modal-bg-color;
  border: 1px solid $modal-border-color-main;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

  &::before {
    content: '';

    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    display: flex;

    width: 100%;
    height: 100%;

    background-color: $white;
  }

  &__title {
    @include noto-sans-font(16px, 19px, 700);
  }

  & hr {
    width: 100%;
    margin: 0;
    margin-bottom: 15px;

    border: none;
    border-bottom: 1px solid $cerulean;
  }

  &__wrapper {
    display: flex;
    margin-bottom: 25px;
  }

  &__label-wrapper {
    display: flex;
    flex-direction: column;

    & label {
      cursor: pointer;

      margin-top: 15px;

      color: $cerulean;
      text-decoration: underline;
      white-space: nowrap;

      &:first-child {
        margin: 0;
      }

      &.disabled {
        cursor: default;
        opacity: 0.5;
      }
    }

    & span {
      margin-top: 15px;
      color: $cerulean;
      white-space: nowrap;
    }
  }

  &__list {
    @include list-reset;

    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    margin-left: 50px;

    box-shadow: 0 4px 4px -3px rgb(0 0 0 / 25%);
  }

  &__upload-btn-wrapper {
    display: flex;
    align-items: center;
  }

  &__progress-bar-wrapper {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
  }

  &__progress-bar-text {
    @include noto-sans-font(20px, 20px);

    &--warning {
      @include noto-sans-font(15px, 15px);
      color: $red;
    }
  }

  &__progress-bar {
    position: relative;

    overflow: hidden;
    display: flex;

    box-sizing: border-box;
    width: 100%;
    height: 35px;
    margin: 10px 0;

    background-color: $white;
    border: 1px solid $silver;
    border-radius: 40px;

    &::before {

      @include progress-bar;
      content: '';

      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      animation: progress 1s linear infinite;
    }

    & div {
      position: absolute;
      top: 0;
      left: 0;

      display: flex;

      width: 0%;
      height: 100%;

      background-color: $cerulean;
    }

    & span {

      @include noto-sans-font(15px, 15px, 700);
      position: absolute;
      z-index: 999;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__status-message {

    @include noto-sans-font(16px, 19px);
    display: none;
    margin-left: auto;

    &--error {
      color: $red;
    }
  }

  &__submit-btn {
    margin-left: auto;
  }

  &__status {
    align-self: flex-end;
  }

  &__close-btn {
    top: 15px;
    right: 15px;
  }
}
