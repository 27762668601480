:root {
  --decrease-ratio: 1;
}

.register-form {
  display: flex;
  flex-direction: column;
  gap: calc(1rem / var(--decrease-ratio));
  align-items: center;

  box-sizing: border-box;
  margin: auto;
  padding: calc(1rem / var(--decrease-ratio));

  border: 1px solid rgb(0 0 0 / 12%);
  border-radius: 4px;

  h2 {
    @include noto-sans-font(calc(1.125rem / var(--decrease-ratio)), calc(1.125rem / var(--decrease-ratio)), 700);
    margin: 0;
  }

  h3 {
    @include noto-sans-font(calc(1rem / var(--decrease-ratio)), calc(1rem / var(--decrease-ratio)));
    margin: 0;
  }

  p {
    @include noto-sans-font(calc(0.8125rem / var(--decrease-ratio)), calc(0.8125rem / var(--decrease-ratio)));
    margin: 0;
  }

  &__analytics {
    --color-light: '';
    --color-dark: '';

    &--label {
      display: flex;
      align-items: baseline;
      align-items: center;
      color: white;

      & span:first-of-type {
        z-index: 1;
        padding: calc(10px / var(--decrease-ratio));
        background-color: var(--color-dark);
        border-radius: 100px;
      }

      & span:last-of-type {
        --padding: calc(6px / var(--decrease-ratio));
        flex-grow: 1;

        margin-left: calc(var(--padding) * 5 * -1);
        padding: var(--padding) var(--padding) var(--padding) calc(6 * var(--padding));

        background-color: var(--color-light);
        border-radius: 100px;
      }
    }
  }

  &__captcha {
    overflow: hidden;
    border: solid 1px rgb(0 0 0 / 23%);
    border-radius: 4px;

    & img {
      aspect-ratio: 16/4;
      width: 100%;
    }
  }

  &__terms-wrapper {
    @include noto-sans-font(calc(1rem / var(--decrease-ratio)), calc(1rem / var(--decrease-ratio)));
    display: flex;
    align-items: center;
  }

  &__confirmation {
    display: flex;
    flex-direction: column;
    gap: calc(1rem / var(--decrease-ratio));
    align-items: center;

    & h3 {
      margin: 0;
    }

    & ul {
      display: flex;
      flex-direction: column;
      gap: calc(0.5rem / var(--decrease-ratio));

      width: 100%;
      margin: 0;
      padding: 0;

      list-style: none;

      & li {
        & :first-child {
          margin-right: 0.1rem;
        }
      }
    }

    &--wrapper {
      display: flex;
      gap: calc(1rem / var(--decrease-ratio));
    }
  }
}

@media (max-width: $tablet-width) {
  .register-form {
    --decrease-ratio: 1.1;

    &__parent {
      justify-content: center;
    }

    &__confirmation {
      min-width: 80vw;

      &--wrapper {
        flex-direction: column;
      }
    }
  }
}

@media (max-width: $mobile-width) {
  .register-form {
    &__confirmation {
      min-width: 80dvw;

      > ul > li {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}
