.react-components-ppi {
  display: flex;
  flex-direction: column;
  width: 65vw;
  margin: 0 auto;

  &__header-wrapper {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  &__title{
    margin: 0;
  }

  &__btn {
    width: 163px;
  }

  &__edit-btn {
    cursor: pointer;

    display: flex;

    font-size: 18px;
    line-height: 26px;
    color: #009EE5;

    &:hover {
      text-decoration: underline;
      opacity: 0.9;
    }
  }

  &__patient-id{
    margin-left: auto;

    font-size: 11px;
    font-style: italic;
    line-height: 13px;
    color: #7F7F7F;
  }

  &__form-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 24px;

    margin-top: 24px;
    padding: 1.875rem;

    border: 1px solid #bebebe;
  }
}
