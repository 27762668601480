// mail-sms-history-modal = mshm
.mshm {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: none;
  flex-direction: column;

  box-sizing: border-box;
  width: 60vw;
  max-height: 60vh;
  padding: 30px 40px 40px;

  background-color: $modal-bg-color;
  border: 1px solid $modal-border-color-additional;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

  & p {
    overflow-y: auto;
    white-space: pre-wrap;
  }
}
