.result-report-status {
  display: flex;
  flex-direction: column;
  width: 65vw;
  margin: 0 auto;

  &__title {
    margin-left: 20px;
  }

  &__list {
    @include list-reset;
    margin-left: 44px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    & p {
      @include noto-sans-font(14px, 16px);
      margin-bottom: 0;
    }

    & div {
      display: flex;
      align-items: flex-end;

      width: 48vw;
      padding-bottom: 10px;

      border-bottom: 1px solid $silver;
    }
  }

  &__link {
    display: flex;
    margin-left: auto;

    &:hover {
      opacity: 0.7;
    }
  }

  &__questionnaire {
    cursor: pointer;
    display: flex;
    margin-left: auto;

    &:hover {
      opacity: 0.7;
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.5;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
