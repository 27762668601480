.confirmation-report {
  &__title {
    display: flex;
    justify-content: center;
    margin: 0;
  }

  &__item {
    &::marker {
      content: '✓';
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__text {
    margin:0;
    font-size: 1.125rem ;

    &:last-of-type {
      margin-bottom: 1rem;
    }
  }


  &__btn-wrapper {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
}
