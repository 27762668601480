@use 'sass:color';

.notification-modal {
  position: fixed;
  z-index: 103;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 60vw;
  max-height: 70vh;
  padding: 50px;

  background-color: $modal-bg-color;
  border: 1px solid $modal-border-color-main;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

  & h2,
  & h3 {
    margin: 0;
  }

  &__table-header {
    @include noto-sans-font(18px, 18px, 500);
    display: flex;
    width: 100%;
    margin-top: 20px;
    border-bottom: 1px solid $silver;

    & span {
      padding: 5px;
      text-align: center;
    }
  }

  &__number {
    width: 5%;
    text-align: center;
  }

  &__date {
    width: 15%;
    text-align: center;
  }

  &__flag {
    width: 8%;
    text-align: center;

    &--color {
      font-size: 25px;
      line-height: 0px;
      color: $cerulean;
    }
  }

  &__title {
    overflow: hidden;

    width: 22%;

    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__description {
    width: 40%;
  }

  &__text-field {
    overflow: overlay;

    width: 100%;
    margin: 20px 0;
    padding: 5px;

    white-space: pre-wrap;
  }

  &__list {
    overflow: overlay;
    display: flex;
    flex-direction: column;

    width: 100%;
    margin: 0;
    padding: 0;

    list-style: none;
  }

  &__item {
    cursor: pointer;
    display: flex;
    width: 100%;
    border-bottom: 1px solid $silver;

    & span {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;

      & div {
        overflow: hidden;
        padding: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &:hover {
      background-color: color.scale($cerulean, $alpha: -90%);
    }
  }

  &__close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
