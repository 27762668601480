.react-components-link {
  cursor: pointer;

  display: flex;

  width: fit-content;
  margin: 0;
  padding: 0;

  color: #009EE5;
  text-decoration: underline;
  text-decoration-color: rgb(0 158 229 / 40%);

  &:hover {
    text-decoration-color: inherit;
  }
}
