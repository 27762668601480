// registration-modal-mri-result-confirmation = rmmrc
.rmmrc {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  &__title {
    margin: 0;
  }

  &__info {
    margin: 0;
    font-weight: 900;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    width: 100%;
    margin: 0;
    padding: 0;

    list-style: none;
  }

  &__item {
    display: flex;

    & span:first-of-type {
      width: 100%;
      max-width: 8rem;
      font-weight: 500;
    }
  }

  &__btn-wrapper {
    display: flex;
    gap: 1rem;
    justify-content: space-evenly;
    width: 100%;
  }

  &__btn {
    flex-shrink: 1;
    width: 100%;
    max-width: 170px;
  }
}
