.remove-visit {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    margin-top: 0;
  }

  &__list {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    display: flex;

    &:not(:last-of-type) {
      margin-bottom: 5px;
    }

    & span:first-of-type {
      width: 100%;
      max-width: 80px;
      font-weight: 500;
    }
  }

  &__warning {
    color: red;
  }

  &__btn-wrapper {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  &__btn {
    flex-shrink: 1;
    width: 100%;
    max-width: 170px;

    &:first-of-type {
      margin-right: 10px;
    }
  }
}
