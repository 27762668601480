[data-react-modal] {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-main {
  z-index: 100;

  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 100vw;
  max-height: 92vh;
  padding: 50px 50px 15px;

  background-color: $modal-bg-color;
  border: 1px solid $modal-border-color-main;
  border-radius: 4px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

  &__close-btn {
    position: absolute !important;
    top: 0;
    right: 0;
  }
}
