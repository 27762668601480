.completed {
  &__title {

    @include noto-sans-font(18px, 21px, 700);
    margin-bottom: 25px;
  }

  &__text {
    @include noto-sans-font(15px, 18px);
  }

  &__btn {
    align-self: center;
    margin-top: 25px;
  }
}
