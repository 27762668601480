.question-tooltip {
  --width: 250px;
  --size: 18px;

  position: relative;

  display: flex;

  width: var(--size);
  height: var(--size);
  margin: 0 3px;

  &__text {
    position: absolute;
    z-index: 102;

    display: none;

    box-sizing: border-box;
    width: var(--width);
    padding: 10px;

    font-size: 14px;
    line-height: 18px;
    color: #000000;

    background-color: #ffffff;
    border: 1px solid #bebebe;
    border-radius: 4px;

    &--top {
      bottom: calc(var(--size) + 2px);
    }

    &--bottom {
      top: calc(var(--size) + 2px);
    }

    &--left {
      right: 0;
    }

    &--right {
      left: 0;
    }
  }

  &__mark {
    cursor: pointer;
    width: var(--size);
    height: var(--size);

    &:hover {
      ~ .question-tooltip__text {
        display: flex;
        color: #ffffff;
        background-color: #616161;
      }
    }
  }
}
