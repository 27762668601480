.create-new-visit {
  &__text {
    margin: 0;
    margin-bottom: 20px;
    padding: 0;
  }

  &__btn-wrapper {
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    & :first-child {
      margin-right: 20px;
    }
  }
}
