@use 'sass:color';

@keyframes progress {
  to {
    background-position: 35px 0;
  }
}

@keyframes slideDownRectangle {
  0% {
    top: -56px;
  }

  100% {
    top: 82px;
  }
}

@keyframes slideDownTriangle {
  0% {
    top: -23px;
  }

  100% {
    top: 115px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 color.scale($cerulean, $alpha: -60%);
  }

  70% {
    box-shadow: 0 0 0 15px color.scale($cerulean, $alpha: -100%);
  }

  100% {
    box-shadow: 0 0 0 0 color.scale($cerulean, $alpha: -100%);
  }
}

@keyframes spinDoctorComment {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes fadeOutDoctorComment {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
