:root {
  --color-cerulean: #009EE5;
  --color-silver: #bebebe;
  --color-mercury: #e9e9e9;
  --color-charcoal: #808080;
  --color-tundora: #464646;
}

// Base Colors
$cerulean: #009EE5;
$white: #ffffff;
$silver: #bebebe;
$mercury: #e9e9e9;
$alabaster: #fafafa;
$midnight-blue: #002063;
$black: #000000;
$red: #ff0000;
$charcoal: #808080;
$tundora: #464646;

// Button Color Settings
$btn-bg-color-main: $cerulean;
$btn-bg-color-additional: $white;
$btn-border-color: $cerulean;
$btn-text-color-main: $white;
$btn-text-color-additional: $cerulean;

// Block Color Settings
$block-border-color-main: $silver;

// Modal Color Settings
$modal-bg-color: $white;
$modal-border-color-main: $cerulean;
$modal-border-color-additional: $silver;
$modal-border-color-delete: $charcoal;

// Patient Toggle Button Color Settings
$patient-toggle-bg-color-active: $cerulean;
$patient-toggle-bg-color-deactivate: $silver;
$patient-toggle-text-color: $white;

// Toggle Color Settings
$toggle-bg-color-active: $cerulean;
$toggle-bg-color-deactivate: $silver;

// Status Indicators Color Settings
$indicators-color-active: $midnight-blue;
$indicators-color-deactivate: $silver;

// Header Color Settings
$header-border-color: $cerulean;
$header-text-color-main: $cerulean;
$header-text-color-additional: $black;

// Input Color Settings
$input-border-color: $silver;

// Label Color Settings
$label-text-color-main: $black;
$label-text-color-additional: $silver;

// Link Color Settings
$link-text-color-main: $cerulean;
$link-text-color-additional: $black;

// Size Settings
// Base width of page
$base-width: 80vw;
$tablet-width: 640px;
$mobile-width: 320px;
$desktop-width: 1000px;
