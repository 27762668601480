* {
  font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;

  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.visually-hidden {
  position: absolute;

  overflow: hidden;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  white-space: nowrap;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  border: 0;
}

.page-404 {
  align-self: center;
  color: $midnight-blue;
}

.wrapper {
  display: flex;
  flex-direction: column;

  width: $base-width;
  margin: 0 auto;
  padding-bottom: 100px;
}

body {
  overflow: hidden;
}

body.base-patients-list {
  box-sizing: border-box;
  height: 100vh;

  & .wrapper {
    box-sizing: border-box;
    height: 87%;
    padding-bottom: 25px;
  }
}

input::placeholder {
  opacity: 0.5;
}

[data-label] {
  cursor: pointer;
  position: relative;
}

[data-label]:hover::after {
  @include noto-sans-font(10px, 11px);
  content: attr(data-label);

  position: absolute;
  z-index: 100;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  width: max-content;
  padding: 4px;

  color: $black;

  background-color: $white;
  border: 1px solid $silver;
  border-radius: 4px;
}

[data-label]:hover::before {
  content: '';

  position: absolute;
  z-index: 101;
  top: 10px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);

  width: 6px;
  height: 6px;

  background-color: $white;
  border-right: 1px solid $silver;
  border-bottom: 1px solid $silver;
}

span[data-label]:hover::after {
  top: -15px;
}

span[data-label]:hover::before {
  top: -5px;
}

.drag-drop-arrow {
  position: absolute;
  z-index: 1;
  right: 10px;
  bottom: 10px;

  overflow: hidden;
  display: flex;

  width: 2.5em;
  height: 2.5em;

  border: 3px solid $block-border-color-main;
  border-radius: 50%;

  transition: right 1s, bottom 1s, width 1s, height 1s, transform 1s;

  &::before,
  &::after {
    content: '';

    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    transition: top 1s, border-width 1s, width 1s, height 1s, transform 1s;
  }

  &::before {
    top: 7.5px;
    width: 8px;
    height: 20px;
    background-color: $block-border-color-main;
  }

  &::after {
    top: 23.5px;

    width: 0;
    height: 0;

    border-top: 11px solid $block-border-color-main;
    border-right: 11px solid transparent;
    border-left: 11px solid transparent;
  }

  & svg {
    display: none;
    width: 90%;
    height: 90%;
    margin: auto;
  }
}

[data-drag-drop='true'] {
  // Just makes the selector heavier.
  &.drag-drop {
    background-color: #bebebe1a;
    border-color: transparent;

    &::before {
      content: '';

      position: absolute;
      top: 0;
      left: 0;

      display: flex;

      box-sizing: border-box;
      width: 100%;
      height: 100%;

      border: 3px dashed $block-border-color-main;
    }

    &::after {
      content: '';

      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;

      display: flex;

      box-sizing: border-box;
      width: 100%;
      height: 100%;

      background-color: $white;
    }

    & > .drag-drop-arrow {
      right: 50%;
      bottom: 50%;
      transform: translate(50%, 50%);

      display: flex;

      width: 5em;
      height: 5em;

      &::before {
        top: 15px;
        width: 16px;
        height: 40px;
      }

      &::after {
        top: 47px;

        width: 0;
        height: 0;

        border-top: 20px solid $block-border-color-main;
        border-right: 20px solid transparent;
        border-left: 20px solid transparent;
      }
    }

    & > *:not(.drag-drop-arrow, .drag-drop__message) {
      opacity: 0.3;
    }

    &--active {
      background-color: #00b9ef1a;

      &::before {
        border-color: $cerulean;
      }

      & > .drag-drop-arrow {
        border-color: $cerulean;

        &::before {
          background-color: $cerulean;
          animation: slideDownRectangle 1s linear 0s infinite;
        }

        &::after {
          border-top-color: $cerulean;
          animation: slideDownTriangle 1s linear 0s infinite;
        }
      }
    }

    &--error {
      background-color: #ff00001a;

      &::before {
        border-color: $red;
      }

      & > .drag-drop-arrow {
        border-color: $red;

        &::before,
        &::after {
          content: none;
        }

        & svg {
          display: flex;
        }
      }
    }
  }
}

.drag-drop {
  &__message {
    display: flex;

    &--info {
      @include noto-sans-font(13px, 18px);
      color: $silver;
    }

    &--error {
      @include noto-sans-font(15px, 18px);
      display: none;
      flex-direction: column;

      & p {
        margin: 0;
        padding: 0;

        & span {
          color: $cerulean;
        }
      }

      & p:first-of-type {
        color: $red;
      }
    }
  }
}

@media (max-width: $tablet-width) {
  .wrapper {
    margin-bottom: 27px;
    padding: 0;
  }
}

.btn {
  @include btn-reset;

  @include noto-sans-font(16px, 18px);

  padding: 8px 26px;
  text-align: center;
  border-radius: 4px;

  &--main {
    @include btn($btn-text-color-main, $btn-bg-color-main);
  }

  &--delete {
    @include btn($btn-text-color-main, $silver);
  }

  &--additional {
    @include btn($btn-text-color-additional, $btn-text-color-additional);
  }

  &--bs {
    @include btn($btn-text-color-main, #1255A2);
  }

  &--lpixel {
    @include btn($btn-text-color-main, #22AC5A);
  }

  &--fix {
    width: 170px;
    height: 35px;
    padding-right: 0;
    padding-left: 0;
  }

  &--small {
    width: 50px;
    height: 35px;
    padding-right: 0;
    padding-left: 0;
  }

  &--delete-icon {
    @include btn($modal-border-color-delete, $modal-border-color-delete);
  }

  &--wide {
    width: 300px;
  }

  &--archive {
    @include noto-sans-font(13px, 15px);
    display: inline-flex;
    color: $midnight-blue;

    &:hover {
      background-color: rgba($color: $midnight-blue, $alpha: 30%);
    }
  }

  &--text {
    color: #009EE5;
    text-decoration: none;
  }

  &--loading {
    @include progress-bar;
    color: $white;
    background-color: $cerulean;
    animation: progress 1s linear infinite;
  }

  &--disabled,
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.btn-close {
  cursor: pointer;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;

  box-sizing: border-box;
  width: 24px;
  height: 24px;

  background: none;
  border: none;
  border: 1px solid $cerulean;

  &::before,
  &::after {
    content: '';

    position: absolute;
    top: 10px;
    right: 4px;
    transform: rotate(-45deg);

    width: 14px;
    height: 2px;

    background-color: $cerulean;
  }

  &::after {
    transform: rotate(45deg);
  }

  &--circle {
    border-radius: 50%;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.capital-text {
  @include noto-sans-font(24px, 34px, 700);
}

.sub-text {
  @include noto-sans-font(18px, 34px, 700);
}

.block {
  box-sizing: border-box;
  width: 65vw;
  margin: 0 auto;
  padding: 30px;

  border: 1px solid $block-border-color-main;
}

#overlay {
  position: fixed;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: none;

  width: calc(100vw * 2);
  height: calc(100vh * 2);

  background-color: rgb(0 0 0 / 50%);
  backdrop-filter: blur(4px);
}


.doctor-comment-loading {
  position: relative;
  box-sizing: border-box;
  width: 20px;
  height: 20px;

  &::before,
  &::after {
    content: "";

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: none;

    width: 15px;
    height: 15px;

    border-radius: 50%;
  }

  &::before {
    z-index: 1;

    border: 4px solid transparent;
    border-top-color: var(--color-silver);
    border-left-color: var(--color-silver);

    animation: spinDoctorComment 1s linear infinite;
  }

  &__active::before {
    display: block;
  }

  &__loaded::after {
    content: "✔";

    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 24px;
    color: green;

    opacity: 1;

    transition: opacity 2s;
    animation: fadeOutDoctorComment 2s forwards;
  }
}

.go-back {
  display: flex;
  width: 65vw;
  margin: 0 auto;

  &__link {
    @include noto-sans-font(18px, 16px, 700);

    display: flex;
    align-items: center;
    color: $cerulean;
    text-decoration: none;

    &:hover {
      .go-back__text {
        text-decoration: underline;
      }
    }

  }

  &__arrow {
    box-sizing: border-box;
    width: 27px;
    height: 27px;
    fill: $cerulean;
  }
}

// DISABLE FUNCTIONS

#disabled {
  cursor: not-allowed;
  opacity: 0.5;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}

div[id^='react-components-tmp'] {
  display: flex;
}
