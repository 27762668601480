.auth-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  box-sizing: border-box;
  margin: auto;
  padding: 1rem;

  border: 1px solid rgb(0 0 0 / 12%);
  border-radius: 4px;

  h3 {
    @include noto-sans-font(18px, 21px, 700);
    margin: 0;
  }

  p {
    margin: 0;
  }

  &__reset-link {
    color: $silver;
  }
}
