@use 'sass:color';

.mri-upload-loading {
  position: absolute;
  z-index: 102;
  top: 0;
  left: 0;

  display: none;
  flex-direction: column;

  box-sizing: border-box;
  width: 100%;
  height: 100%;

  background-color: white;

  &__progress-bar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    overflow: hidden;
    display: flex;

    box-sizing: border-box;
    width: calc(100% - 2rem);
    height: 35px;

    background-color: $white;
    border: 1px solid $silver;
    border-radius: 40px;
    box-shadow: 0 0 0 color.scale($cerulean, $alpha: -60%);

    animation: pulse 2s infinite;

    &::before {
      @include progress-bar;
      content: '';

      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      animation: progress 1s linear infinite;
    }

    & div {
      position: absolute;
      top: 0;
      left: 0;

      display: flex;

      width: 0%;
      height: 100%;

      background-color: $cerulean;
    }

    & span {
      @include noto-sans-font(15px, 15px, 700);
      position: absolute;
      z-index: 999;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
