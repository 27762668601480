@use 'sass:color';

@mixin noto-sans-font($size, $line-height, $weight: normal) {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: $size;
  font-weight: $weight;
  font-style: normal;
  line-height: $line-height;
}

@mixin mincho-font($size, $line-height, $weight: normal) {
  font-family: 'Sawarabi Mincho', sans-serif;
  font-size: $size;
  font-weight: $weight;
  font-style: normal;
  line-height: $line-height;
}

@mixin input-reset() {
  background: none;
  border: none;
}

@mixin list-reset() {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin progress-bar() {
  background-image: linear-gradient(
    -45deg,
    rgb(255 255 255 / 15%) 25%,
    transparent 25%,
    transparent 50%,
    rgb(255 255 255 / 15%) 50%,
    rgb(255 255 255 / 15%) 75%,
    transparent 75%,
    transparent
  );
  background-repeat: repeat-x;
  background-size: 35px 35px;
}

@mixin btn-reset() {
  cursor: pointer;

  display: flex;
  flex-shrink: 0;
  align-self: center;
  justify-content: center;

  box-sizing: border-box;

  text-decoration: none;

  background: none;
  border: none;
}

// If element on which you apply this mixin <button> use attribute 'disabled' to disable button.
// If element on which you apply this mixin <a> use class modifier by BEM methodology 'btn--disabled' to disable link.
@mixin btn($text-color, $bg-color) {
  $btn: $bg-color;
  $btn-bg: $btn;
  $btn-bg-hover: color.scale($btn, $blackness: 10%);
  $btn-shadow-hover: color.scale($btn, $blackness: 20%);
  $btn-shadow-active1: color.scale($white, $alpha: -60%);
  $btn-shadow-active2: color.scale($btn, $whiteness: 30%, $alpha: -50%);

  @if $bg-color == $text-color {
    $btn: $text-color;
    $btn-bg: $white;
    $btn-bg-hover: $white;
    box-shadow: inset 0 0 0 1px $text-color;
  }

  cursor: pointer;

  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;

  box-sizing: border-box;

  color: $text-color;
  text-align: center;

  background-color: $btn-bg;
  border-radius: 4px;

  &--disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.4;
  }

  &:disabled {
    cursor: default;
    opacity: 0.4;
  }

  &:hover:not(:disabled) {
    background-color: $btn-bg-hover;
    box-shadow: inset 0 0 0 2px $btn-shadow-hover;
  }

  &:active:not(:disabled) {
    box-shadow: inset 0 0 0 2px $btn-shadow-hover, 0 0 0 1px $btn-shadow-active1, 0 0 0 4px $btn-shadow-active2;
  }
}
