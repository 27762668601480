.header {
  display: flex;
  margin-bottom: 1rem;
  padding: 10px 0;
  border-bottom: 1px solid $header-border-color;

  &__wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    width: $base-width;
    margin: 0 auto;
  }

  &__link {
    text-decoration: none;

    &:hover {
      opacity: 0.7;
    }
  }

  &__title {
    @include noto-sans-font(20px, 23px);
    margin: 0;
    color: $header-text-color-main;
    text-decoration: none;

    span {
      @include noto-sans-font(16px, 19px);
      color: $header-text-color-additional;
    }
  }

  &__nav {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__hospital-name {
    @include noto-sans-font(16px, 25px);
    margin-right: 30px;
  }

  &__nav-list {
    display: none;
  }

  &__btn-nav-label {
    cursor: pointer;

    position: relative;

    box-sizing: border-box;
    width: 35px;
    height: 35px;

    &--notification {
      @include noto-sans-font(14px, 1px);

      position: absolute;
      top: -8px;
      right: -8px;

      display: none;
      align-items: center;
      justify-content: center;

      box-sizing: border-box;
      min-width: 19px;
      min-height: 19px;
      padding-bottom: 1px;

      color: $white;

      background-color: $cerulean;
      border-radius: 3px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__btn-nav-input {
    margin-left: 3vw;

    &:checked {
      & ~ .header__btn-nav-label {
        & svg {
          animation: spin 2s linear infinite;
        }
      }

      & ~ .header__nav-list {
        display: flex;
      }
    }
  }

  &--patients {
    margin-top: 1rem;
    padding: 17px 0;
    background-color: $cerulean;
    border: none;

    & .header__title {
      @include mincho-font(16px, 16px);
      margin: 0;
      color: $white;
    }

    & .header__link {
      &:hover {
        opacity: 1;
      }
    }
  }
}
