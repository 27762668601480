@use 'sass:color';

.registration-modal {
  &__drag-drop {
    position: relative;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    width: 100%;
    padding: 1rem 0;

    border: 2px dashed rgb(0 0 0 / 23%);
    border-radius: 4px;

    &--active {
      border-color: $cerulean;
      box-shadow: 0 0 0 color.scale($cerulean, $alpha: -60%);
      animation: pulse 2s infinite;
    }

    & p {
      margin: 0;
      color: #aaaaaa;
      text-align: center;

      &:first-of-type {
        font-weight: 700;
      }
    }
  }

  &__loading {
    position: absolute;
    z-index: 102;
    top: -5px;
    left: -5px;

    display: none;
    flex-direction: column;

    box-sizing: border-box;
    width: calc(100% + 10px);
    height: calc(100% + 10px);

    background-color: white;
  }

  &__loading-progress-bar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    overflow: hidden;
    display: flex;

    box-sizing: border-box;
    width: 90%;
    height: 35px;

    background-color: $white;
    border: 1px solid $silver;
    border-radius: 40px;
    box-shadow: 0 0 0 color.scale($cerulean, $alpha: -60%);

    animation: pulse 2s infinite;

    &::before {
      @include progress-bar;
      content: '';

      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      animation: progress 1s linear infinite;
    }

    & div {
      position: absolute;
      top: 0;
      left: 0;

      display: flex;

      width: 0%;
      height: 100%;

      background-color: $cerulean;
    }

    & span {
      @include noto-sans-font(15px, 15px, 700);
      position: absolute;
      z-index: 999;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__loading-pre-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 200%);

    width: max-content;
  }

  &__manual {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    width: 50%;

    font-size: 0.875rem;
  }

  &__border {
    border: 1px solid;

    &--primary {
      border-color: var(--color-cerulean);
    }

    &--secondary {
      border-color: var(--color-charcoal);
    }
  }

  &__header {
    padding: 0.5rem;

    &--primary {
      font-size: 0.875rem;
      font-weight: 700;
      color: var(--color-cerulean);
      background-color: #E3F6FF;
    }

    &--secondary {
      font-size: 0.875rem;
      color: $black;
      background-color: var(--color-mercury);
    }
  }

  &__link {
    display: flex;
    width: 100%;

    & hr {
      width: 1px;
      height: inherit;
      margin: 0 50px;
      padding: 0;

      background: none;
      background-color: rgb(0 0 0 / 12%);
      border: none;
    }
  }

  &__link-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    width: calc(50% - 25.5px);
    padding: 1rem 0;

    & h5 {
      align-self: center;
      margin: 0;
    }
  }

  &__img {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    padding: 1rem 0;

    & h5 {
      align-self: center;
      margin: 0;
    }

    & img {
      display: block;

      &:hover {
        box-shadow: 0 0 0 color.scale($cerulean, $alpha: -60%);
        animation: pulse 2s infinite;
      }
    }
  }
}
