$cerulean: #009EE5;

.loading-screen {
  position: fixed;
  z-index: 150;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 110vw;
  height: 110vh;

  background-color: white;
}

.lds-spinner {
  --size: 40px;

  position: fixed;
  z-index: 151;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: inline-block;

  width: var(--size);
  height: var(--size);
}

.lds-spinner div {
  transform-origin: calc(var(--size) / 2) calc(var(--size) / 2);
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div::after {
  content: '';

  position: absolute;
  top: calc(var(--size) / 100 * 3.75);
  left: calc(var(--size) / 100 * 46.25);

  display: block;

  width: calc(var(--size) / 100 * 7.5);
  height: calc(var(--size) / 100 * 22.5);

  background: $cerulean;
  border-radius: 20%;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media (min-width: $tablet-width) {
  .lds-spinner {
    --size: 80px;
  }
}
