$black: #000000;
$cerulean: #009EE5;

.confirmation-sheet {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', Osaka, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic',
    sans-serif;

  & h2 {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  & table {
    border-collapse: collapse;
    text-align: center;
    white-space: break-spaces;
  }

  & td,
  & th {
    box-sizing: border-box;
    max-width: 450px;
    height: 30px;
    padding: 5px;

    border: 1px solid $black;
  }

  & th {
    background-color: #afccf4;
  }

  &__header {
    text-align: left;
  }

  &__width {
    width: 150px;

    &--resize {
      width: 150px;
    }
  }

  &__long-width {
    width: 250px;
  }

  &__margin {
    margin-bottom: 30px;
  }

  &__expand {
    margin-bottom: 30px;
  }

  &__clear-border {
    border: 0 !important;
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: flex-end;

    & ~ table,
    & ~ div {
      margin-top: 15px;
    }
  }

  &__button {
    cursor: pointer;

    position: absolute;
    bottom: 0;
    left: -35px;

    width: 30px;
    height: 30px;
    margin: 0;
    margin-right: 10px;
    padding: 0;

    background: none;
    border: none;
    border: 1px solid #afccf4;
    border-radius: 50%;

    &::before,
    &::after {
      content: '';

      position: absolute;
      top: 50%;
      left: 50%;

      width: 3px;
      height: 21px;

      background-color: #afccf4;
    }

    &::before {
      transform: translate(-50%, -50%);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &--open {
      &::before {
        content: none;
      }
    }
  }
}
