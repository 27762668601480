.reset-request-form {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-top: 10vh;

  &__label {
    @include noto-sans-font(14px, 16px);
    margin-top: 2vh;
    color: $label-text-color-main;
  }

  &__input {
    @include input-reset;
    @include noto-sans-font(14px, 16px);

    box-sizing: border-box;
    width: 20vw;
    margin-top: 1vh;
    margin-bottom: 2vh;
    padding: 5px;

    line-height: 20px;

    border-bottom: 1px solid $input-border-color;
    outline-color: $cerulean;

    &:hover {
      border-color: $cerulean;
    }
  }

  &__submit-btn {
    align-self: flex-end;
    margin-top: 15px;
  }
}
