// update-patient-info-form = upif
.upif {
  display: grid;
  grid-template-areas:
    "brain-id hospital-id report-language"
    "name kanji-name birth-date"
    "email gender submit-btn";
  gap: 20px 0;

  &__label {
    @include noto-sans-font(14px, 16px);
    display: flex;
    flex-direction: column;
    width: 70%;
    color: $label-text-color-main;
  }

  &__input {
    @include input-reset;

    box-sizing: border-box;
    margin-top: 5px;
    padding: 5px;

    line-height: 20px;

    border-bottom: 1px solid $input-border-color;
    outline-color: $cerulean;

    &:hover {
      border-color: $cerulean;
    }
  }

  &__input-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 70%;

    &:nth-child(3n + 4) {
      padding: 0;
    }

    & span {
      @include noto-sans-font(14px, 16px);
      width: 100%;
      color: $label-text-color-main;
    }

    & label {
      width: 48%;
      margin: 0;
    }

    &--radio {
      justify-content: space-between;
      height: 52px;
      border-bottom: 1px solid $input-border-color;

      & label {
        @include noto-sans-font(14px, 16px);
        cursor: pointer;
        width: auto;
      }

      &:hover {
        border-color: $cerulean;
      }
    }
  }

  & button {
    width: 80%;
    margin-top: auto;
  }

  &__report-language-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    border-bottom: 1px solid $input-border-color;

    > select {
      border: none;

      &:focus {
        outline: none;
      }
    }
  }
}
