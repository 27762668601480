.template-modal {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: none;

  &__body {
    z-index: 101;

    display: none;
    flex-direction: column;

    box-sizing: border-box;
    width: 60vw;
    height: 60vh;
    padding: 30px 40px 40px;

    background-color: $modal-bg-color;
    border: 1px solid $modal-border-color-additional;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

    & textarea {
      resize: none;
      margin-top: 20px;
    }
  }

  &__info {
    cursor: pointer;

    position: absolute;
    z-index: 103;
    bottom: 0;
    left: 0;

    width: 40px;
    height: 40px;

    & span {
      position: absolute;
      top: 40px;
      left: 0;

      display: none;

      width: max-content;
      padding: 5px;

      background-color: $white;
      border: 1px solid $cerulean;
    }

    &:hover {
      & span {
        display: block;
      }
    }
  }

  &__row {
    @include noto-sans-font(14px, 16px);
    display: flex;
    align-items: flex-end;
    padding: 10px 0;
    border-bottom: 1px solid $silver;

    & span:first-of-type,
    & label {
      width: 150px;
    }

    & input {
      flex-grow: 1;
      border: none;
    }

    & select {
      cursor: pointer;
      width: 400px;
    }

    & label,
    & input {
      @include noto-sans-font(14px, 16px);
    }

    & select,
    & option {
      @include noto-sans-font(13px, 15px);
    }
  }

  &__toggle {
    @include noto-sans-font(18px, 21px);
    position: absolute;
    z-index: 102;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    width: 200px;
    height: 50px;

    color: $black;

    background-color: $modal-bg-color;
    border: 1px solid $modal-border-color-additional;
    border-bottom: none;

    &--email {
      top: -49px;
      left: 0;
    }

    &--sms {
      top: -49px;
      left: 230px;
    }
  }

  &__toggle-input {
    &--email {
      &:checked {
        ~ .template-modal__toggle--sms {
          cursor: pointer;

          z-index: 100;
          top: -39px;

          height: 40px;

          background-color: $mercury;
        }
      }
    }

    &--sms {
      &:checked {
        ~ .template-modal__toggle--email {
          cursor: pointer;

          z-index: 100;
          top: -39px;

          height: 40px;

          background-color: $mercury;
        }
      }
    }

    &:checked {
      & + form {
        display: flex;
      }
    }
  }

  &__text-field {
    overflow: auto;

    height: 100%;
    margin-top: 0;
    margin-bottom: 5px;
    padding: 10px 6px;

    line-height: 30px;

    outline: none;

    &:focus {
      margin-top: -1px;
      margin-bottom: 4px;
      padding: 10px 5px;
      border: 1px solid $silver;
    }
  }

  &__finish {
    cursor: pointer;

    margin-right: 2.5px;
    margin-left: 2.5px;
    padding: 5px;

    background-color: $mercury;
    border-radius: 5px;
  }

  &__submit-btn {
    align-self: flex-end;
  }

  &__reset-btn {
    align-self: flex-end;
    text-decoration: underline;
    border: none;
  }

  &__close-btn {
    position: absolute;
    z-index: 102;
    top: -23px;
    right: 0;

    background-color: $white;
    border-color: $silver;
    border-bottom: none;

    &::before,
    &::after {
      background-color: $silver;
    }
  }
}

.template-names-modal {
  position: fixed;
  z-index: 101;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: none;
  flex-direction: column;

  padding: 5px;

  background-color: $white;
  border: 1px solid $cerulean;
  border-radius: 4px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  & li {
    cursor: pointer;
    margin-top: 5px;
  }

  & li:hover {
    color: $cerulean;
  }

  &--show {
    display: flex;
  }
}
