// TODO: get rid of this shit!
@use 'sass:color';

.patient-table {
  $collapse-btn-size: 30px;

  &__title {
    @include noto-sans-font(18px, 21px, 700);
  }

  &__row-collapse {
    position: relative;
  }

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: none;
  }

  &__btn-collapse {
    cursor: pointer;

    position: absolute;
    top: 50%;
    left: -$collapse-btn-size;
    transform: translateY(-50%);

    width: $collapse-btn-size;
    height: $collapse-btn-size;
    margin: 0;
    padding: 0;

    background: none;
    border: none;
    border: 1px solid $cerulean;
    border-radius: 50%;

    &::before,
    &::after {
      content: '';

      position: absolute;
      top: 50%;
      left: 50%;

      width: 3px;
      height: 21px;

      background-color: $cerulean;
    }

    &::before {
      transform: translate(-50%, -50%);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &--open {
      &::before {
        content: none;
      }
    }
  }

  &__row-child {
    background-color: color.scale($cerulean, $alpha: -90%);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  &__registration-link {
    display: flex;

    & span {
      position: relative;

      flex-shrink: 0;

      box-sizing: border-box;
      width: 18px;
      height: 18px;
      margin-right: 5px;

      &::before,
      &::after {
        content: '';

        position: absolute;
        top: 50%;
        left: 50%;

        width: 13px;
        height: 2px;

        background-color: $white;
      }

      &::before {
        transform: translate(-50%, -50%);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }

  &__table {
    border-collapse: collapse;
    flex-shrink: 0;
    width: 100%;
    height: fit-content;

    & tbody {
      & tr {
        border-bottom: 1px solid $silver;
      }

      & th {
        @include noto-sans-font(15px, 18px);

        padding-top: 15px;
        padding-bottom: 15px;
        color: $black;

        & button {
          display: inline-flex;
        }
      }

      & td {
        @include noto-sans-font(13px, 15px);
        padding-top: 15px;
        padding-bottom: 15px;
        color: $black;
        text-align: center;

        & button {
          display: inline-flex;
        }
      }
    }

    & thead {
      border-bottom: 1px solid $silver;

      & th {
        @include noto-sans-font(15px, 18px);
        padding: 1vw 0;
        color: $black;

        &:first-child {
          padding: 1vw 1vh;
        }
      }
    }
  }

  &__wrapper-list {
    position: relative;

    overflow-x: hidden;
    overflow-y: auto;
    display: flex;

    height: 100%;
    margin-left: -$collapse-btn-size;
    padding-left: $collapse-btn-size;
  }

  &__indicator-wrapper {
    display: inline-flex;
    justify-content: center;
    width: 100%;

    & span:first-of-type {
      margin-left: 10px;
    }
  }

  &__indicator {
    position: relative;

    width: 10px;
    height: 10px;
    margin-top: 9px;
    margin-right: 5px;

    background-color: $indicators-color-deactivate;

    &--active {
      background-color: $indicators-color-active;
    }

    &--off {
      background-color: transparent;
    }
  }

  &__confirm {
    font-size: 25px;
    color: $cerulean;
  }

  &__table-name {
    @include noto-sans-font(14px, 16px);
    color: $cerulean;
    text-decoration: none;

    &:hover {
      color: $midnight-blue;
      text-decoration: underline;
    }
  }
}
