.support-modal {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: none;
  flex-direction: column;

  box-sizing: border-box;
  width: 60vw;
  height: 60vh;
  padding: 30px 40px 40px;

  background-color: $modal-bg-color;
  border: 1px solid $modal-border-color-additional;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

  & hr {
    display: flex;

    width: 100%;
    height: 1px;
    margin: 15px 0;

    background-color: $silver;
    border: none;
  }

  & label,
  & input,
  & textarea {
    @include noto-sans-font(14px, 20px);
  }

  & textarea {
    resize: none;

    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin-top: 5px;
    padding: 5px;

    line-height: 20px;

    border: 1px solid $silver;
    outline-color: $cerulean;

    &:hover {
      border-color: $cerulean;
    }
  }

  & input {
    box-sizing: border-box;
    width: 45%;
    padding: 5px;

    line-height: 20px;

    border: none;
    border-bottom: 1px solid $silver;
    outline-color: $cerulean;

    &:hover {
      border-color: $cerulean;
    }
  }

  &__submit-btn {
    align-self: flex-end;
    margin-top: 40px;
  }

  &__close-btn {
    position: absolute;
    z-index: 102;
    top: -24px;
    right: -1px;

    background-color: $white;
    border-color: $silver;
    border-bottom: none;

    &::before,
    &::after {
      background-color: $silver;
    }
  }
}
