.react-components-ppiwm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  & h3 {
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 700;
  }

  & p {
    margin: 0;
    padding: 0;

    font-size: 18px;
    font-weight: 700;
    color: #FF0000;
  }

  & button {
    width: 130px;
    padding-right: 0;
    padding-left: 0;
  }
}
