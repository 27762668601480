/* noto-sans-jp-300 - latin_japanese */
@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 300;
  font-style: normal;
  src: local(''),
       url('../fonts/noto-sans-jp-v28-latin_japanese-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/noto-sans-jp-v28-latin_japanese-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-jp-regular - latin_japanese */
@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 400;
  font-style: normal;
  src: local(''),
       url('../fonts/noto-sans-jp-v28-latin_japanese-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/noto-sans-jp-v28-latin_japanese-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-jp-500 - latin_japanese */
@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 500;
  font-style: normal;
  src: local(''),
       url('../fonts/noto-sans-jp-v28-latin_japanese-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/noto-sans-jp-v28-latin_japanese-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-jp-700 - latin_japanese */
@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 700;
  font-style: normal;
  src: local(''),
       url('../fonts/noto-sans-jp-v28-latin_japanese-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/noto-sans-jp-v28-latin_japanese-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* sawarabi-mincho-regular - latin_japanese */
@font-face {
  font-family: 'Sawarabi Mincho';
  font-weight: 400;
  font-style: normal;
  src: local(''),
       url('../fonts/sawarabi-mincho-v10-latin_japanese-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/sawarabi-mincho-v10-latin_japanese-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
