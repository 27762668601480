.nav-list {
  @include list-reset;

  position: absolute;
  z-index: 100;
  top: 36px;
  right: 0;

  overflow-y: auto;
  flex-direction: column;

  width: max-content;
  max-height: 70vh;
  padding: 25px;

  background-color: $modal-bg-color;
  border: 1px solid $modal-border-color-main;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

  & hr {
    width: 100%;
    border: none;
    box-shadow: 0 0 4px 1px rgb(0 0 0 / 25%);
  }

  &__item {

    @include noto-sans-font(16px, 19px);
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;

    &:first-child {
      align-self: center;
      margin-top: 0;
      margin-bottom: 10px;
      font-weight: 700;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &--separator {
      margin: 10px 0;
      font-weight: 700;
      text-decoration: underline;
    }

    &--moved {
      margin-left: 10px;
    }

    &--toggle {
      position: relative;
      justify-content: space-between;

      & span {
        flex-grow: 1;
      }

      & label {
        cursor: pointer;

        position: relative;

        flex-shrink: 0;

        width: 35px;
        height: 18px;
        margin-left: 20px;

        background-color: $toggle-bg-color-deactivate;
        border-radius: 20px;

        &::before {
          content: '';

          position: absolute;
          top: 2px;
          left: 2px;

          width: 14px;
          height: 14px;

          background-color: $white;
          border-radius: 50%;
        }
      }

      & input {
        &:checked {
          &+ label {
            background-color: $toggle-bg-color-active;

            &::before {
              left: 19px;
            }
          }
        }
      }
    }

    &--notification {

      @include noto-sans-font(16px, 16px);

      display: none;

      box-sizing: border-box;
      margin-left: 5px;
      padding: 0 5px;

      color: $white;

      background-color: $cerulean;
      border-radius: 3px;
    }
  }

  &__tooltip {

    @include noto-sans-font(14px, 18px);
    position: absolute;
    z-index: 101;
    top: 19px;
    left: -5px;

    display: none;

    width: 230px;
    padding: 10px;

    color: $black;

    background-color: $white;
    border: 1px solid $silver;
    border-radius: 4px;
  }

  &__link {
    @include noto-sans-font(16px, 19px);

    cursor: pointer;
    color: $link-text-color-additional;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
