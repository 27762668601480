.examination-details {
  display: flex;
  flex-direction: column;
  width: 65vw;
  margin: 0 auto;

  &__links {
    display: flex;
  }

  &__links-item {
    z-index: 1;

    display: flex;

    margin-bottom: -1px;
    padding: 7px;

    background-color: $mercury;
    border: 1px solid $silver;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &:not(:only-child) {
      &:last-of-type {
        align-self: center;

        margin-bottom: 0;
        margin-left: 1rem;

        background-color: $white;
        border: none;
      }
    }

    &--active {
      background-color: $white;
      border-bottom-color: $white;
    }
  }

  &__wrapper-main {
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    padding: 1rem 1.875rem;

    border: 1px solid $silver;
  }

  &__title {
    &:not(:first-of-type) {
      margin-top: 20px;
    }
  }

  &__prepaid {
    display: flex;
    gap: 1em;
  }

  &__visit {
    @include noto-sans-font(16px, 17px);

    cursor: pointer;
    margin: 0;
    margin-left: 25px;

    & span {
      margin-left: 10px;
    }
  }

  &__status {
    @include noto-sans-font(16px, 18.4px);
    display: flex;
    flex-shrink: 0;

    box-sizing: border-box;
    width: 34px;
    height: 34px;
    margin-right: 10px;
    padding-top: 7px;
    padding-left: 10px;

    color: $silver;

    border: 1px solid $silver;
    border-radius: 50%;

    &--active {
      color: $white;
      background-color: $midnight-blue;
      border-color: $midnight-blue;
    }

    &--arrow {
      padding-top: 4px;
      padding-left: 8.5px;
    }

    &--off {
      width: 34px;
      height: 34px;
      margin-right: 10px;
    }
  }

  &__mri-processing {
    @include noto-sans-font(14px, 16px);
    display: flex;
    margin-left: auto;

    &--waiting {
      position: relative;

      overflow: hidden;

      box-sizing: border-box;
      padding: 5px 30px;

      font-weight: 500;

      background-color: $cerulean;
      border: 1px solid $silver;
      border-radius: 40px;

      &::before {
        @include progress-bar;
        content: '';

        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
      }
    }

    &--active {
      &::before {
        animation: progress 1s linear infinite;
      }
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    align-items: end;

    max-width: max-content;
  }

  &__wrapper-reports {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;

    & p {
      @include noto-sans-font(14px, 16px);
      margin: 0;
    }

    & p:first-child {
      @include noto-sans-font(16px, 18px, 700);
    }
  }

  &__wrapper-exams {
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    width: 100%;
    margin-top: 16px;
  }

  &__upload-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__exam {
    position: relative;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    justify-content: space-between;

    box-sizing: border-box;
    width: 100%;
    padding: 15px;

    border: 1px solid $silver;

    & label {
      @include noto-sans-font(14px, 16px);

      cursor: pointer;

      position: relative;

      display: flex;
      gap: 0.5rem;


      min-width: 190px;
      min-height: 40px;

      color: $cerulean;

      &.examination-details__report-z-label {
        min-width: auto;
        min-height: auto;
        margin-right: auto;

        &::after {
          content: '';

          position: absolute;
          bottom: -3px;
          left: 50%;

          width: 0;
          height: 2px;

          background-color: $cerulean;

          transition: all 0.3s ease-in-out;
        }
      }
    }

    & input {
      &:disabled {
        & + label {
          cursor: default;
          opacity: 0.5;
        }
      }
    }

    :not(input:disabled) {
      & + label {
        &:hover {
          &::after {
            left: 0;
            width: 100%;
          }
        }
      }
    }

    &--empty {
      align-items: center;
      justify-content: center;
    }

    &--dashed {
      border: 1px dashed rgb(204 204 204);
    }
  }

  &__drag-drop-message {
    margin-bottom: 0.5rem;
  }

  &__report {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto auto;
    justify-items: start;

    padding: 1rem;

    border: 1px solid $silver;

    &-label {
      padding: 6px;
      color: $cerulean;
      background: #D8F3FF;
      border-radius: 3px;
    }

    &-images {
      display: flex;
      grid-area: 1 / 2 / 3 / 3;
      flex-direction: column;
      justify-self: end;

      max-width: 150px;

      border: 1px solid $silver;
    }

    &-item{
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &-buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: flex-end;
    }

    & img {
      display: block;
    }
  }

  &__exam-item {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $silver;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__mail {
    cursor: pointer;
    display: flex;
    margin-left: auto;

    &:hover {
      opacity: 0.7;
    }
  }

  &__delete {
    cursor: pointer;
    display: flex;
    margin-left: auto;

    &:hover {
      opacity: 0.7;
    }

    &.disabled {
      pointer-events: none;
      cursor: default;
      opacity: 0.5;
    }
  }



  &__textarea {
    resize: none;

    width: 100%;
    padding: 7px;

    line-height: 1.5;

    border: 1px solid $silver;
    outline-color: $cerulean;
    outline-width: 1px;
  }

  &__brain-viewer-link {
    color: #009EE5;
    text-decoration: underline;
    text-decoration-color: rgb(0 158 229 / 40%);

    &:hover {
      text-decoration-color: inherit;
    }
  }
}
