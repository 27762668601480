.flash-message {
  position: fixed;
  z-index: 149;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &--error {
    & .flash-message__accept-btn {
      @include btn(white, #ef5350);
    }

    & .flash-message__reject-btn {
      @include btn(#ef5350, #ef5350);
    }
  }

  &--warning {
    & .flash-message__accept-btn {
      @include btn(white, #ff9800);
    }

    & .flash-message__reject-btn {
      @include btn(#ff9800, #ff9800);
    }
  }

  &--success {
    & .flash-message__accept-btn {
      @include btn(white, #4caf50);
    }

    & .flash-message__reject-btn {
      @include btn(#4caf50, #4caf50);
    }
  }

  &--info {
    & .flash-message__accept-btn {
      @include btn(white, #03a9f4);
    }

    & .flash-message__reject-btn {
      @include btn(#03a9f4, #03a9f4);
    }
  }

  &__btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;

    & button {
      &:nth-child(even) {
        margin-left: 20px;
      }
    }
  }
}
